import React from 'react';
import { Card } from 'antd';

export default function PromptCard({ prompt, onClick }) {
  return (
    <Card
      className="prompt-card"
      title={prompt.title}
      hoverable={true}
      onClick={onClick}
    >
      <p className="prompt-desc">{prompt.uid}</p>
      <p className="prompt-desc">{prompt.template}</p>
    </Card>
  );
}
