import React from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Table } from 'antd';
import { getUsers } from '../../services/api.service';
import { Bar } from 'react-chartjs-2';

export default function Feedback() {
    const { data: users } = useQuery({
        queryKey: ['users'],
        queryFn: getUsers,
    });

    const filteredUsers = users && Array.isArray(users) ? users.filter((user) => user.userInfo && Object.keys(user.userInfo).length > 0) : [];

    const whoData = filteredUsers.reduce((acc, user) => {
        const who = user.userInfo.who;
        if (who) {
            acc[who] = (acc[who] || 0) + 1;
        }
        return acc;
    }, {});

    const whatData = filteredUsers.reduce((acc, user) => {
        const what = user.userInfo.what;
        if (what) {
            acc[what] = (acc[what] || 0) + 1;
        }
        return acc;
    }, {});

    const whoChartData = {
        labels: Object.keys(whoData),
        datasets: [{
            label: 'Count of Who',
            data: Object.values(whoData),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
        }]
    };

    const whatChartData = {
        labels: Object.keys(whatData),
        datasets: [{
            label: 'Count of What',
            data: Object.values(whatData),
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1
        }]
    };

    return (
        <ContentPage title="Feedback">
            <div className="row p-3">
                <div className="col-md-6">
                    <div className="mt-3 p-3">
                        <h2>Chart Based on Who</h2>
                        <Bar data={whoChartData} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mt-3 p-3">
                        <h2>Chart Based on What</h2>
                        <Bar data={whatChartData} />
                    </div>
                </div>
            </div>
            <Table
                dataSource={filteredUsers}
                pagination={{
                    pageSize: 100,
                }}
                rowKey="id"
              >
                <Table.Column key="name" title="Name" dataIndex={'name'} />
                <Table.Column key="email" title="Email" dataIndex={'email'} />
                <Table.Column
                    key="who"
                    title="Who"
                    render={(text, record) => record.userInfo.who}
                />
                <Table.Column
                    key="what"
                    title="What"
                    render={(text, record) => record.userInfo.what}
                />
                <Table.Column
                    key="goals"
                    title="Goals"
                    render={(text, record) => {
                        const goals = record.userInfo.goals;
                        return goals ? goals.join(', ') : '';
                    }}
                />
                <Table.Column
                    key="frequency"
                    title="Frequency"
                    render={(text, record) => record.userInfo.frequency}
                />
            </Table>

        </ContentPage>
    )
}
