// import Modal from 'antd/es/modal/Modal';
import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { MODALS, useModals } from '../../context/modalContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPromptApi } from '../../services/api.service';

export default function CreatePrompt() {
    const modalCtx = useModals();
    const hideCurrentModal = () => {
        if (loading) return;
        modalCtx.hideModal(MODALS.CREATE_PROMPT_MODAL);
    };
    const [loading, setLoading] = React.useState(false);
    const isShowing = modalCtx.modals.showCreatePromptModal;
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: createPromptApi,
        onSuccess: (data) => {
            console.log('data', data);
            queryClient.invalidateQueries('prompts');
            setTimeout(() => {
                setLoading(false);
                hideCurrentModal();
            }, 1500);
        },
    });

    const handleSubmit = (values) => {
        console.log(values);
        setLoading(true);
        mutation.mutate(values);
    };

    return (
        <Modal
            open={isShowing}
            width={600}
            footer={false}
            title={'Create a New Prompt'}
            onCancel={hideCurrentModal}
            destroyOnClose={true}
        >
            <Form layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Prompt Title"
                    name={'title'}
                    rules={[
                        {
                            required: true,
                            message: 'Please input prompt title!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Uid"
                    name={'uid'}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Template"
                    name={'template'}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                    >
                        Create Prompt
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
