import React from 'react';
import './Modals.scss';
import CreatePrompt from './CreatePrompt/CreatePrompt';
import CreateSubscription from './CreateSubscription/CreateSubscription';
import AddCredits from './AddCredits/AddCredits';

// import { useAuth } from '../context/authContext';

export default function ModalWrapper() {
  // const { user } = useAuth();

  // if (!user) {
  //   return null;
  // }

  return (
    <div>
      <CreatePrompt />
      <CreateSubscription />
      <AddCredits />
    </div>
  );
}
