// import Modal from 'antd/es/modal/Modal';
import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { MODALS, useModals } from '../../context/modalContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSubscriptionApi } from '../../services/api.service';

export default function CreateSubscription() {
  const modalCtx = useModals();
  const hideCurrentModal = () => {
    if (loading) return;
    modalCtx.hideModal(MODALS.CREATE_SUBSCRIPTION);
  };
  const [loading, setLoading] = React.useState(false);
  const isShowing = modalCtx.modals.showCreateSubscriptionModal;
  const modalData = modalCtx.modals.data;
  const projectId = modalData?.projectId;
  const userId = modalData?.userId;

  const handleSubmit = (values) => {
    createSubscriptionApi(userId, {
      projectId: projectId,
      stripeCustomerId: values.stripeCustomerId,
    })
      .then((res) => {
        hideCurrentModal();
      })
      .catch((err) => {
        message.error('Failed to create subscription');
      });
  };

  return (
    <Modal
      open={isShowing}
      width={600}
      footer={false}
      title={'Create a New Subscription'}
      onCancel={hideCurrentModal}
      destroyOnClose={true}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Stripe CustomerId"
          name={'stripeCustomerId'}
          rules={[
            {
              required: true,
              message: 'Please input stripe customerId!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Create Subscription
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
