// import Modal from 'antd/es/modal/Modal';
import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { MODALS, useModals } from '../../context/modalContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSubscriptionApi, createWordsCredit } from '../../services/api.service';

export default function AddCredits() {
  const modalCtx = useModals();
  const hideCurrentModal = () => {
    if (loading) return;
    modalCtx.hideModal(MODALS.ADD_CREDIT_MODAL);
  };
  const [loading, setLoading] = React.useState(false);
  const isShowing = modalCtx.modals.showAddCreditModal;
  const modalData = modalCtx.modals.data;
  const projectId = modalData?.projectId;
  const userId = modalData?.userId;

  const handleSubmit = (values) => {
    createWordsCredit({
      projectId: projectId,
      credits: values.total,
      validTill: values.date,
    })
      .then((res) => {
        hideCurrentModal();
      })
      .catch((err) => {
        message.error('Failed to create subscription');
      });
  };

  return (
    <Modal
      open={isShowing}
      width={600}
      footer={false}
      title={'Create a New Subscription'}
      onCancel={hideCurrentModal}
      destroyOnClose={true}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Total Credits"
          name={'total'}
          rules={[
            {
              required: true,
              message: 'Please add credits',
            },
          ]}
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          label="Valid Till"
          name={'date'}
          rules={[
            {
              required: true,
              message: 'Please input a date',
            },
          ]}
        >
          <Input type='date' />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            Add Credits
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
