import React, { useEffect } from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { Col, Row, Segmented, Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getAllUsage } from '../../services/api.service';
import { Link } from 'react-router-dom';

export default function UsagePage() {
  const [filter, setFilter] = React.useState('today');

  const { data: usage, isLoading } = useQuery({
    queryKey: ['usage', filter],
    queryFn: () => getAllUsage({ days: filter }),
  });

  return (
    <ContentPage title="Users">
      <Row>
        <Col className="p-4">
          <Segmented
            selected={filter}
            onChange={(value) => {
              setFilter(value);
            }}
            options={[
              { label: 'Today', value: 1 },
              { label: 'Last 7 days', value: 7 },
              { label: 'Last 30 days', value: 30 },
              { label: 'All time', value: 365 },
            ]}
          />
        </Col>
      </Row>

      {!isLoading && (
        <Table
          dataSource={usage}
          pagination={{
            pageSize: 100,
          }}
        >
          <Table.Column
            key="name"
            title="Name"
            dataIndex={['user', 'name']}
            render={(text, record) => {
              console.log('Text:', text);
              console.log('Record:', record);
              return <Link to={`/users/${record.userId}`}>{text}</Link>;
            }}
          />

          <Table.Column
            key={'email'}
            title={'Email'}
            dataIndex={['user', 'email']}
          />
          <Table.Column
            key={'projectId'}
            title={'ProjectId'}
            dataIndex={['project', 'title']}
          />
          <Table.Column key={'tokens'} title={'Tokens'} dataIndex={'tokens'} />
        </Table>
      )}
    </ContentPage>
  );
}
