import { Card, Col, Empty, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { getPrompts } from '../../services/api.service';
import PromptCard from '../../components/prompts/ProjectCard/PromptCard';
import { useNavigate } from 'react-router-dom';
import UIButton from '../../components/ui/UIButton/UIButton';
import { useQuery } from '@tanstack/react-query';
import { MODALS, useModals } from '../../context/modalContext';

export default function PromptsList({ onAddClick }) {
  const navigate = useNavigate();
  const { showModal } = useModals();

  const { data: prompts } = useQuery({
    queryKey: ['prompts'],
    queryFn: getPrompts,
  });

  console.log('prompts', prompts);

  const handleAddPromptClick = () => {
    console.log('Add Prompt clicked');
    showModal(MODALS.CREATE_PROMPT_MODAL);
  };

  const openPromptForEditing = (prompt) => {
    console.log('open prompt for editing', prompt);
    navigate('/prompts/' + prompt._id);
  };

  return (
    <div>
      <Row gutter={[20, 20]}>
        {prompts?.map((prompt) => (
          <Col span={8} key={prompt._id}>
            <PromptCard
              prompt={prompt}
              onClick={() => openPromptForEditing(prompt)}
            />
          </Col>
        ))}
        {prompts?.length === 0 && (
          <Col span={24} className="bg-white p-5">
            <Empty description="Create your first Prompt">
              <UIButton title="Create Prompt" onClick={handleAddPromptClick} />
            </Empty>
          </Col>
        )}
      </Row>
    </div>
  )
}
