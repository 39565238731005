import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  createSubscriptionApi,
  getSingleUser,
  getUserProjects,
} from '../../services/api.service';
import { Card, Col, Descriptions, Row, Statistic, Button, Space } from 'antd';
import { MODALS, useModals } from '../../context/modalContext';

export default function SingleUserPage() {
  const params = useParams();
  const userId = params.id;

  const { data: user } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getSingleUser(userId),
  });

  const { data: projectData } = useQuery({
    queryKey: ['projects', userId],
    queryFn: () => getUserProjects(userId),
  });
  const { showModal } = useModals();
  const projects = projectData?.projects;
  console.log('projects', projects);

  const handleAddSubscriptionClick = (projectId) => {
    showModal(MODALS.CREATE_SUBSCRIPTION, { userId: userId, projectId: projectId });
  };

  const handleAddCredits = (projectId) => {
    showModal(MODALS.ADD_CREDIT_MODAL, { userId: userId, projectId: projectId });
  };

  return (
    <div className="p-5">
      <Card title="User info">
        <Descriptions>
          <Descriptions.Item label="Name">{user?.name}</Descriptions.Item>
          <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
          <Descriptions.Item label="Email Verified">
            {user?.isEmailVerified ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Created At">
            {user?.createdAt}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      {projects?.map((project) => {
        return (
          <div className="p-3 bg-light border my-3">
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Card title={project?.title}>
                  <Descriptions>
                    {/* <Descriptions.Item label="Title">
                      {project?.title}
                    </Descriptions.Item> */}
                    <Descriptions.Item label="id">
                      {project?._id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Brand Name">
                      {project?.brandName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Website">
                      <a href={project?.website} target="_blank">
                        {project?.website}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="About">
                      {project?.about}
                    </Descriptions.Item>
                    <Descriptions.Item label="Goals">
                      {project?.goals}
                    </Descriptions.Item>
                    <Descriptions.Item label="Audience">
                      {project?.audience}
                    </Descriptions.Item>
                    <Descriptions.Item label="Target">
                      {project?.target}
                    </Descriptions.Item>
                    <Descriptions.Item label="Upgrade">
                      <Space size={15}>
                        <Button size="small" onClick={() => handleAddSubscriptionClick(project._id)}>
                          Upgrade
                        </Button>
                        <Button size="small" onClick={() => handleAddCredits(project._id)}>
                          Add Credits
                        </Button>
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Topics" extra={project?.topics.length}>
                  <ul>
                    {project?.topics.map((topic) => {
                      return <li>{topic.title}</li>;
                    })}
                  </ul>
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  title="Content Strategies"
                  extra={project?.strategies.length}
                >
                  <ul>
                    {project?.strategies.map((strategy) => {
                      return <li>{strategy.keywords?.join(',')}</li>;
                    })}
                  </ul>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="Content" extra={project?.contents.length}>
                  <ul>
                    {project?.contents.map((content) => {
                      return <li>{content.title}</li>;
                    })}
                  </ul>
                </Card>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
}
