import React from 'react';
import { getPromptApi } from '../../services/api.service';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export default function SinglePromptPage() {
    const params = useParams();
    const promptId = params.promptId;

    const { data: prompt } = useQuery({
        queryKey: ['prompt', promptId],
        queryFn: () => getPromptApi(promptId)
    });
    return (
        <div>
            <h1 className="m-3">
                {prompt?.title}
            </h1>
        </div>
    )
}
