import React from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { Button, Form, Input, Modal, Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getProjects, updateProjects } from '../../services/api.service';
import moment from 'moment';

export default function ProjectsPage() {
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: getProjects,
  });

  const [showUpdateModel, setShowUpdateModel] = React.useState({
    open: false,
    projectId: null,
  });

  const handleUpdateModal = (projectId) => {
    setShowUpdateModel({
      open: true,
      projectId,
    });
  };

  const getModelName = () => {
    const projectId = showUpdateModel.projectId;
    if (showUpdateModel.open) {
      const project = projects.find((project) => project._id === projectId);
      return project?.settings?.contentModel;
    }
  };

  const handleCloseModal = () => {
    setShowUpdateModel({
      open: false,
      projectId: null,
    });
  };

  const handleSubmit = (values) => {
    console.log(values);
    const update = {
      projectId: showUpdateModel.projectId,
      update: {
        settings: {
          contentModel: values.model,
        },
      },
    };
    updateProjects(update).then((response) => {
      console.log(response);
      handleCloseModal();
    });
  };

  return (
    <ContentPage title="projects">
      <Table
        dataSource={projects}
        pagination={{
          pageSize: 100,
        }}
      >
        <Table.Column key={'title'} title={'Title'} dataIndex={'title'} />
        <Table.Column
          key={'brandName'}
          title={'Brand Name'}
          dataIndex={'brandName'}
        />
        <Table.Column
          key={'brandName'}
          title={'Model'}
          dataIndex={'project.settings'}
          render={(_, record) => record.settings?.contentModel}
        />
        <Table.Column
          key={'createdAt'}
          title={'Date Created'}
          dataIndex={'createdAt'}
          render={(date) => moment(date).format('MMM Do YYYY, hh:mm a')}
        />
        <Table.Column
          key={'actions'}
          title={'Actions'}
          dataIndex={'actions'}
          render={(_, record) => {
            return (
              <div>
                <Button
                  title="Update"
                  content="Update"
                  size="small"
                  onClick={() => handleUpdateModal(record._id)}
                >
                  Update model
                </Button>
              </div>
            );
          }}
        />
      </Table>
      <Modal
        title="Update Model"
        open={showUpdateModel.open}
        footer={false}
        onCancel={handleCloseModal}
      >
        <Form
          layout="vertical"
          className="my-3"
          initialValues={{
            model: getModelName(),
          }}
          onFinish={handleSubmit}
        >
          <Form.Item label="Content Model" name={'model'}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Model
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </ContentPage>
  );
}
