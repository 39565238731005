import React from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { Button, Popconfirm, Table, message } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteSingleUser, getUsers } from '../../services/api.service';
import { Link } from 'react-router-dom';
import { TbTrash } from 'react-icons/tb';

export default function UsersPage() {
  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
  });
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: deleteSingleUser,
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      message.success('User deleted successfully');
    }
  });

  const deleteUser = async (record) => {
    deleteMutation.mutate(record._id);
  };

  return (
    <ContentPage title="Users">
      <Table
        dataSource={users}
        pagination={{
          pageSize: 100,
        }}
      >
        <Table.Column
          key={'name'}
          title={'Name'}
          dataIndex={'name'}
          render={(text, record) => (
            <Link to={`/users/${record.id}`}>{text}</Link>
          )}
        />
        <Table.Column key={'email'} title={'Email'} dataIndex={'email'} />
        <Table.Column key={'phone'} title={'Phone'} dataIndex={'phone'} />
        <Table.Column
          key={'isEmailVerified'}
          title={'Email verified'}
          dataIndex={'isEmailVerified'}
          render={(text) => {
            return text === true ? 'True' : 'False';
          }}
        />
        <Table.Column
          key={'createdAt'}
          title={'Date Created'}
          dataIndex={'createdAt'}
        />
        <Table.Column
          key="actions"
          title="Actions"
          render={(text, record) => (
            <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => deleteUser(record)}>
              <Button size='small' to={`/users/${record.id}`}>
                <TbTrash />
              </Button>
            </Popconfirm>
          )}
        />
      </Table>
    </ContentPage>
  );
}
