import React from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { Table, Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { getPrompts } from '../../services/api.service';
import UIButton from '../../components/ui/UIButton/UIButton';
import { MODALS, useModals } from '../../context/modalContext';
import PromptsList from './PromptsList';


export default function UsersPage() {
    const { data: prompts } = useQuery({
        queryKey: ['prompts'],
        queryFn: getPrompts,
    });

    console.log('prompts', prompts);

    const { showModal } = useModals();

    const handleAddPromptClick = () => {
        console.log('Add Prompt clicked');
        showModal(MODALS.CREATE_PROMPT_MODAL);
    };

    return (
        <ContentPage title="Prompts"
            actions={
                <Space size={10}>
                    <UIButton title={'New Prompt'} onClick={handleAddPromptClick} />
                </Space>
            }
        >
            {/* <Table dataSource={prompts}>
                <Table.Column key={'title'} title={'Title'} dataIndex={'title'} />
                <Table.Column key={'uid'} title={'Uid'} dataIndex={'uid'} />
                <Table.Column key={'template'} title={'Template'} dataIndex={'template'} />
                <Table.Column
                    key={'createdAt'}
                    title={'Date Created'}
                    dataIndex={'createdAt'}
                />
            </Table> */}
            <PromptsList />
        </ContentPage>
    );
}
