import React from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { Table } from 'antd';
import { getUsers } from '../../services/api.service';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function DashboardPage() {
  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
  });
  const setUserData = (users) => {
    const last7Days = Array.from({ length: 7 }, (_, i) =>
      moment().subtract(i, 'days').format('ddd, MMMM D')
    ).reverse();

    const registrations = last7Days.map((date) => {
      return users?.filter(
        (user) => moment(user.createdAt).format('ddd, MMMM DD') === date
      ).length;
    });

    return {
      labels: last7Days,
      datasets: [
        {
          label: 'New Users Registered',
          data: registrations,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  const chartData = setUserData(users);

  return (
    <ContentPage title="Dashboard">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-10">
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: 'User Registrations in the Last 7 Days',
                  },
                  legend: {
                    display: true,
                    position: 'top',
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
              height={400}
            />
          </div>
        </div>
      </div>

    </ContentPage>
  )
}
