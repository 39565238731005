import React from 'react';
import './ContentPage.scss';

export default function ContentPage({ title, actions, children }) {
  return (
    // <div className="content-page">
    //   <div className="content-page-header">
    //     <h3>{title}</h3>
    //   </div>
    //   <div className="col-md-12 text-end">
    //     <div className="dashboard-actions">{actions}</div>
    //   </div>
    //   <div className="content-page-main">
    //     <div className="content-page-wrapper">{children}</div>
    //   </div>
    // </div>
    <div className="content-page">
      <div className="content-page-header">
        <div className="row">
          <div className="col-md-8">
            <h3>{title}</h3>
          </div>
          <div className="col-md-4 text-end">
            <div className="dashboard-actions">{actions}</div>
          </div>
        </div>
      </div>
      <div className="content-page-main">
        <div className="content-page-wrapper">{children}</div>
      </div>
    </div>
  );
}
