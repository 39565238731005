'use client';
import { createContext, useContext, useState } from 'react';

export const MODALS = {
    CREATE_PROMPT_MODAL: 'showCreatePromptModal',
    CREATE_SUBSCRIPTION: 'showCreateSubscriptionModal',
    ADD_CREDIT_MODAL: 'showAddCreditModal',
};

const initialState = {
    showCreatePromptModal: false,
    showCreateSubscriptionModal: false,
    showAddCreditModal: false
};

export const ModalContext = createContext({
    modals: initialState,
    showModal: () => { },
    hideModal: () => { },
});

const initialData = {

};

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState({
        ...initialState,
        data: initialData,
    });

    const showModal = (modal, data) => {
        const state = {
            ...initialState,
            [modal]: true,
            data: {
                ...modals.data,
                ...data,
            },
        };
        console.log('state', state);
        setModals(state);
    };

    const hideModal = (modal) => {
        const state = {
            ...modals,
            [modal]: false,
            data: initialData,
        };
        setModals(state);
    };

    return (
        <ModalContext.Provider
            value={{
                modals,
                showModal,
                hideModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModals = () => useContext(ModalContext);