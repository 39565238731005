import axios from "./axios";

export const getUsers = (body) => {
  return axios.get('/admin/users', body).then(({ data: response }) => {
    return response.data;
  });
};

export const getAllUsage = async ({ days }) => {
  return axios.get('/admin/usage?days=' + days).then(({ data: response }) => {
    return response.data;
  });
};

export const getSingleUser = async (id) => {
  return axios.get('/admin/users/' + id).then(({ data: response }) => {
    return response.data;
  });
};

export const createSubscriptionApi = async (id, body) => {
  return axios.post('/admin/users/' + id + '/upgrade', body).then(({ data: response }) => {
    return response.data;
  });
};

export const deleteSingleUser = async (id) => {
  console.log('delete user', id);
  return axios.delete('/admin/users/' + id + '/delete').then(({ data: response }) => {
    return response.data;
  });
};

export const getUserProjects = async (id) => {
  return axios.get(`/admin/users/${id}/projects`).then(({ data: response }) => {
    return response.data;
  });
};

export const getProjects = (body) => {
  return axios.get('/admin/projects', body).then(({ data: response }) => {
    return response.data;
  });
};

export const updateProjects = (body) => {
  return axios.put('/admin/projects', body).then(({ data: response }) => {
    return response.data;
  });
};

export const getPrompts = (body) => {
  return axios.get('/prompts', body).then(({ data: response }) => {
    return response.data;
  });
};

export const getSubscriptions = (body) => {
  return axios.get('/admin/subscriptions', body).then(({ data: response }) => {
    return response.data;
  });
};

export const extendSubscriptionApi = (body) => {
  return axios.post('/admin/subscriptions/extend', body).then(({ data: response }) => {
    return response.data;
  });
};

export const createPromptApi = async (body) => {
  return axios.post('/prompts', body).then(({ data: response }) => {
    return response.data;
  });
};

export const getPromptApi = async (promptId) => {
  return axios.get('/prompts/' + promptId).then(({ data: response }) => {
    return response.data;
  });
};

export const createWordsCredit = async ({
  projectId,
  credits,
  validTill,
}) => {
  return axios.post('/admin/add-credits', {
    projectId: projectId,
    credits: credits,
    validTill: validTill,
  }).then(({ data: response }) => {
    return response.data;
  });
};

