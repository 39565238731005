import { Button, Form, Input, Modal, Row, Table, Tag } from 'antd';
import React from 'react';
import ContentPage from '../../components/ContentPage/ContentPage';
import { extendSubscriptionApi, getSubscriptions } from '../../services/api.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

export default function Subscriptions() {
  const queryClient = useQueryClient();
  const { data: subscriptions } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: getSubscriptions,
  });

  const [selectedUser, setSelectedUser] = React.useState(null);

  const handleExtendTrial = (values) => {
    const days = parseInt(values.days) || 15;
    extendSubscriptionApi({
      subscriptionId: selectedUser._id,
      days,      
    }).then((res) => {
      setSelectedUser(null);
      console.log(res);
      queryClient.invalidateQueries({
        queryKey: ['subscriptions'],
      })
    });
  }

  return (
    <ContentPage title="Subscriptions">
      <Table
        pagination={false}
        columns={[
          {
            title: 'User',
            dataIndex: 'user',
            render: (_, record) => (
              <div>
                {record.userId?.name}
                <br />
                {record.userId?.email}
              </div>
            ),
          },
          {
            title: 'Customer Id',
            dataIndex: 'stripeCustomerId',
          },
          {
            title: 'Expiry',
            dataIndex: 'expiry',
            render: (expiry) =>
              expiry && moment(expiry).format('DD MMM YYYY hh:mm a'),
          },
          {
            title: 'Expired',
            dataIndex: 'expiry',
            render: (expiry) =>
              expiry && moment(expiry).isBefore(moment()) ? (
                <Tag color="red">Expired</Tag>
              ) : (
                ''
              ),
          },
          {
            title: 'State',
            dataIndex: 'subscriptionState',
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
              <Row>
                <Button
                  type="primary"
                  onClick={() => {
                    console.log('Extend', record);
                    setSelectedUser(record);
                  }}
                >
                  Extend
                </Button>
              </Row>
            ),
          }
        ]}
        dataSource={subscriptions}
      />
      <Modal open={!!selectedUser} title="Extend a Trial" footer={false}>
        <Form layout="vertical" onFinish={handleExtendTrial}>
          <Form.Item label="User">
            <Input value={selectedUser?.userId.email} disabled />
          </Form.Item>
          <Form.Item label="How many days?" name={"days"}>
            <Input type="number" defaultValue={15} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType='submit'>Extend</Button>
          </Form.Item>
        </Form>
      </Modal>
    </ContentPage>
  );
}
