import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DashboardPage from './pages/Dashboard/DashboardPage';
import UsersPage from './pages/UsersPage/UsersPage';
import UsagePage from './pages/UsagePage/UsagePage';
import SingleUserPage from './pages/UsersPage/SingleUserPage';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import PromptsPage from './pages/PromptsPage/PromptsPage';
import MainLayout from './components/layouts/MainLayout';
import ModalWrapper from './modals/ModalWrapper';
import SinglePromptPage from './pages/PromptsPage/SinglePromptPage';
import Feedback from './pages/Feedback/Feedback';
import Subscriptions from './pages/Subscriptions/Subscriptions';
export const queryClient = new QueryClient();

function App() {
  return (
    <div>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MainLayout>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/usage" element={<UsagePage />} />
              <Route path="/users/:id" element={<SingleUserPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/prompts" element={<PromptsPage />} />
              <Route path="/prompts/:promptId" element={<SinglePromptPage />} />
              <Route path="/prompts/:promptId" element={<SinglePromptPage />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
            </Routes>
          </MainLayout>
          <ModalWrapper />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
