import React from 'react';
import { Layout } from 'antd';
import './MainLayout.scss';
import { Link } from 'react-router-dom';

export default function MainLayout({ children }) {
  return (
    <Layout className="main-layout">
      <Layout.Sider>
        <div className="main-navigation">
          <ul>
            <li>
              <Link to={'/'}>Dashboard</Link>
            </li>
            <li>
              <Link to={'/users'}>Users</Link>
            </li>
            <li>
              <Link to={'/projects'}>Projects</Link>
            </li>
            <li>
              <Link to={'/prompts'}>Prompts</Link>
            </li>
            <li>
              <Link to={'/usage'}>Usage</Link>
            </li>
            <li>
              <Link to={'/feedback'}>Feedback</Link>
            </li>
            <li>
              <Link to={'/subscriptions'}>Subscriptions</Link>
            </li>
          </ul>
        </div>
      </Layout.Sider>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
}
